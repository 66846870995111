import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Partnerships = () => {

    return (
        <Layout>
            <SEO title="Partnerships" />
            <div className="partnershipHero">
                <div className="container">
                    <a download href="/downloads/restorativ_press_release.pdf" className="btn uppercase">Read the press release</a>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="container-fluid mb-4">
                    <div className="partnerships">
                        <h1 className="mb-3 text-center">Access <span className="partnershipsScale">Scale</span> <span className="partnershipsEco">Economics</span></h1>
                        <p>It’s time to scale, provide more access to, and deliver an economic model for Restorative Practices. This is a next-generation, tech partnership, with a social enterprise mandate, that funnels all profits back into the industry it supports. Everyone wins!</p>
                        <ul className="partnershipsList">
                            <li className="partnershipsListItem">
                                <div className="partnershipsListIcon"><StaticImage src='../images/partnerships/icon_user.svg' alt='' /></div>
                                <p>Our strategic partnership with the RJC paves the way for expanding restorative practices across multiple industries. It all starts with providing a next-gen solution for traditional Restorative Justice operations across the UK. Our focus is to provide a mobile-first application that is targeted at Facilitators, who are at the “coal face” of the RJ World. Restorativ integrates the “RJC Membership Profile” providing ease of data capture and communications with its members. Combine this with the Restorativ app, that manages cases from the palm of a facilitator’s hand, and our partnership provides the ACCESS needed to bring RJ into the mainstream.</p>
                            </li>

                            <li className="partnershipsListItem partnershipsListItemAlt">
                                <p> Next we offer the ability to SCALE restorative practices into other industries. Education, Corporations, Media and Sports businesses can all utlise a fully virtual RJ solution and outsourcing capability to help them manage dispute resolution and rebuild relationships within their operations. </p>
                                <div className="partnershipsListIcon"><StaticImage src='../images/partnerships/icon_world.svg' alt='' /></div>

                            </li>
                            <li className="partnershipsListItem">
                                <div className="partnershipsListIcon"><StaticImage src='../images/partnerships/icon_community.svg' alt='' /></div>
                                <p>Our approach is unique in the fact that we are a registered Community Interest Corporation and we have a charter to channel all profits back into the RJ world, via this amazing partnership with the RJC. In doing that we can keep the ECONOMICS of supporting our facilitators at a very low cost and provide much needed funds to continue all the good research and policy making the RJC provides.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="partnershipsBanner">
                <div className="container">
                    <div className="mb-3">
                        <StaticImage width={280} className="mr-3" src="../images/restorativ-justice-council-logo-white.png" alt='' />
                        <StaticImage width={290} src="../images/restorativ-logo-white.png" alt='' />
                    </div>
                    <a href="https://restorativejustice.org.uk/" target="blank" className="h2 partnershipsBannerLink">Learn more about this partnership</a>
                </div>

            </div>

        </Layout>
    )
}

export default Partnerships
